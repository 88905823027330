<template>
	<div class="CorpList">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		mounted() {},
		methods: {
			
		}
	}
</script>

<style>
	.CorpList{
		height: 100%;
		/* overflow-y: scroll; */
	}
</style>
